let today = new Date();
let mm = today.getMonth() + 1;
let dd = today.getDate();
let yyyy = today.getFullYear();
$.fn.dataTable.ext.errMode = 'none';
window.currentDate = (mm < 10 ? '0' : '') + mm + "/" + (dd < 10 ? '0' : '') + dd + "/" + yyyy;

const _gnky = () => {
    return new Promise(resolve => {
        
        var settings = {
            "url": "/Ibaan/OnlineServices/api/gnky",
            "method": "GET",
            "headers": {
                "Authorization": ""
            },
        };
        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
    });

    /* return new Promise(resolve => {

        _gnky().then((authToken) => {
            var settings = {
                "url": "link",
                "method": "POST",
                "headers": {
                    "Authorization": Buffer.from(authToken.token).toString('base64')
                },
            };

            $.ajax(settings)
                .always(function (msg) {
                    resolve(msg);
                });
        });

    }); */
}

const _logout = () => {

    return new Promise(resolve => {
        _gnky().then((authToken) => {
        var settings = {
            "url": "/Ibaan/OnlineServices/api/logout",
            "method": "POST",
            "headers": {
                "Authorization": Buffer.from(authToken.token).toString('base64')
            },
        };

        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
        });

    });

}

const _link = () => {
    return new Promise(resolve => {
        _gnky().then((authToken) => {
        var settings = {
            "url": "/Ibaan/OnlineServices/api/link",
            "method": "POST",
            "headers": {
                "Authorization": Buffer.from(authToken.token).toString('base64')
            },
        };
        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
        });

    });
}

const _notif = (icon, message) => { /* error success question warning */
    Swal.fire({
        icon: icon,
        html: message,
        focusConfirm: false,
        allowOutsideClick: false,
        showClass: {
            popup: 'swal2-show',
            backdrop: 'swal2-backdrop-show',
        },
        confirmButtonText:  'Okay'
    })
}

const _showLoading = (message) => { 
    Swal.fire({
        html:  '<div class="fs-3 mt-2"><i class="fas fa-spinner fa-pulse loading-icon"></i> <br> <label class="fs-5">' + (message == undefined ? "Please wait..." : (message == "" ? "Please wait..." : message)) + "</label></div>",
        showConfirmButton: false,
        allowOutsideClick: false,
        showClass: {
            popup: 'swal2-show',
            backdrop: 'swal2-backdrop-show',
        },
    });
}
const _hideLoading = () => { 
    Swal.fire({showConfirmButton: false}).close();
}


const _numbersOnly = (event) => {
    let key = window.event ? event.keyCode : event.which;

    if (key === 8 || key === 46) {
        return true;
    }else if(key === 45){  /* - */
        return false;
    }else if(key === 32){  /* space */
        return false;
    }else if(event.shiftKey  && key === 40){  /* ( */
        return false;
    }else if(event.shiftKey  && key === 41){ /* ) */
        return false;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else {
        return true;
    }
}

const _thousandsFormat = (param) => {
    param = param.replace(/,/g, '');
;    let thousands = (param == "" || isNaN(param) || param == undefined ? "0.00" : param).toString().split(".");
    thousands[0] = parseFloat(thousands[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(thousands[1] === undefined) {
        thousands[1] = "00";
    }
    return thousands.join(".");
}

const _validatePassword = () => {
    $('[name="password"], [name="password_confirm"]').on("cut copy paste",function(e) {
        e.preventDefault();
    });

    let myInput = document.getElementById("password");
    let letter = document.getElementById("letter");
    let capital = document.getElementById("capital");
    let number = document.getElementById("number");
    let length = document.getElementById("length");
    let special = document.getElementById("special");

    // When the user clicks on the password field, show the message box
    myInput.onfocus = function() {
        document.getElementById("message").style.display = "block";
    }


    // When the user clicks outside of the password field, hide the message box
    myInput.onblur = function() {
        document.getElementById("message").style.display = "none";
    }

    // When the user starts to type something inside the password field
    myInput.onkeyup = function() {
    // Validate lowercase letters
    let lowerCaseLetters = /[a-z]/g;
    if(myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
    } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if(myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
    } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
    }

    // Validate numbers
    let numbers = /[0-9]/g;
    if(myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
    } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
    }

    // Validate length
    if(myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
    } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
    }

    //Validate Special Character
    let alphaNumeric = /[$&+,:;=?@#|'<>._^*()%!-]/g;
    if (myInput.value.match(alphaNumeric)) {
        special.classList.remove("invalid");
        special.classList.add("valid");
    } else {
        special.classList.remove("valid");
        special.classList.add("invalid");
    }
    }
}

const _ShowPassword = (_event, _this) => {
    $(_this).toggleClass("fa-eye fa-eye-slash");
    let input = $($(_this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
}

const thousandsFormat = (param) => {
    let thousands = (param == "" || isNaN(param) || param == undefined ? "0.00" : param).toString().split(".");
    thousands[0] = parseFloat(thousands[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return thousands.join(".");
}

const _FormDataToJSON = (formData) => {
    let ConvertedJSON = {};
    for (const [key, value] of formData.entries()) {
        ConvertedJSON[key] = value;
    }

    return JSON.stringify(ConvertedJSON);
}

var _formDataToJSON = function _formDataToJSON(formData) {
    /* 
    
    const   fData = new FormData($("#")[0]);
            fData.append('', '');
            fData.append('', '');
      const fDataFinal = _formDataToJSON(fData); */
  
    var ConvertedJSON = {};
    var _iterator = _createForOfIteratorHelper(formData.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
          key = _step$value[0],
          value = _step$value[1];
        ConvertedJSON[key] = value;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return JSON.stringify(ConvertedJSON);
};

const _updateAcctManger = () => {
    return new Promise(resolve => {
        _gnky().then((authToken) => {
            var settings = {
                "url": "/Ibaan/OnlineServices/api/acctManagerSave",
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": Buffer.from(authToken.token).toString('base64')
                },
                "data": JSON.stringify({
                    "accManager_fname" : $('#accManager-fname').val(),
                    "accManager_mname" : $('#accManager-mname').val(),
                    "accManager_lname" : $('#accManager-lname').val(),
                    "accManager_addr" : $('#accManager-addr').val(),
                    "accManager_number" : $('#accManager-number').val()
                })
            };
            $.ajax(settings)
                .always(function (url) {
                    resolve(url);
                });
        });
    });
};

const _download_file = (fileURL, fileName) => {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;

		if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
			document.location = save.href; 
			// window event not working here
		}else{
			var evt = new MouseEvent('click', {
				'view': window,
				'bubbles': true,
				'cancelable': false
			});
			save.dispatchEvent(evt);
			(window.URL || window.webkitURL).revokeObjectURL(save.href);
		}	
    }
    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

var _formGetDataNotIsset = function _formGetDataNotIsset(form_id) {
    /* _formGetDataNotIsset('form id name'); */
  
    $('#' + form_id + ' .form-new-input-cr').remove();
    var divElemCreated = '<div class="form-new-input-cr d-none">';
    $('#' + form_id + ' input[type=checkbox]').each(function () {
      if ($(this).attr('name') != undefined) {
        divElemCreated += '<input type="hidden" name="' + $(this).attr('name') + '" value="' + ($(this).is(':checked') ? 'true' : 'false') + '">';
      }
    });
    var radioName = [];
    $('#' + form_id + ' input[type=radio]').each(function () {
      if ($.inArray($(this).attr('name'), radioName) != -1) {
        if ($('#' + form_id + ' input[name="' + $(this).attr('name') + '"]:checked').val() == undefined) {
          divElemCreated += '<input type="hidden" name="' + $(this).attr('name') + '" value="">';
        } else {
          divElemCreated += '<input type="hidden" name="' + $(this).attr('name') + '" value="' + $('#' + form_id + ' input[name="' + $(this).attr('name') + '"]:checked').val() + '">';
        }
      } else {
        radioName.push($(this).attr('name'));
      }
    });
    $('#' + form_id + ' input:disabled').each(function () {
      divElemCreated += '<input type="hidden" name="' + $(this).attr('name') + '" value="' + $(this).val() + '">';
    });
    divElemCreated += '</div>';
    $('#' + form_id).append(divElemCreated);
    setTimeout(function () {
      $('#' + form_id + ' .form-new-input-cr').remove();
    }, 2000);
};

window._gnky = _gnky;
window._logout = _logout;
window._link = _link;
window._notif = _notif;
window._showLoading = _showLoading;
window._hideLoading = _hideLoading;
window._numbersOnly = _numbersOnly;
window._thousandsFormat = _thousandsFormat;
window._validatePassword = _validatePassword;
window._ShowPassword = _ShowPassword;
window._FormDataToJSON = _FormDataToJSON;
window._formDataToJSON = _formDataToJSON;
window._download_file = _download_file;
window._formGetDataNotIsset = _formGetDataNotIsset;

$(() => {
    $(".datepicker").datepicker();

    $.extend(true, $.fn.dataTable.defaults, {
        ajax: {
            method: "POST",
        }
    });

    $('#accManager-Save').on('click', function(){  
        
        $(this).prop('disabled', true);
        _updateAcctManger().then((data) => {
            $(this).prop('disabled', false);
            if(data.response.success){              
                Swal.fire({
                    html: data.response.outMessage,
                    icon: 'success',
                    confirmButtonColor: '#dc3545',
                    confirmButtonText: 'OK',                    
                    focusConfirm: true,
                    allowOutsideClick: false,
                    showClass: {
                        popup: 'swal2-show',
                        backdrop: 'swal2-backdrop-show',
                    },
                }).then((result) => {
                })
                $('#acctManagerModal').modal('hide')
                /* return false; */
            }else{
                _notif('warning', data.response.outMessage)
                return false;
            }
            
        })
    }); 

    $("input[type='text']").attr('autocomplete', 'a');


    $('.numbersOnly').keypress(_numbersOnly);
    $('.thousandFormat').keypress(_numbersOnly);

    $(".thousandFormat").on('focusin', function(e) {
        $(this).val($(this).val().replace(/,/g, ''));
    })
    $(".thousandFormat").on('focusout', function(e) {
        $(this).val(thousandsFormat(parseFloat($(this).val()).toFixed(2)));
    })


    window.onscroll = () => {

        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
            $(".btn-scroll-top").css('display','block');
        } else {
            $(".btn-scroll-top").css('display','none');
        }
    };
    $(".btn-scroll-top").off().on('click', () => {
        window.scroll({top: 0, behavior: "smooth"});
    });


    $('.sidebarCollapse-btn').off().on('click', () => {

        $('.sidebar').toggleClass('active');
        $('.sidebarCollapse-btn').toggleClass('active');
        $('.content').toggleClass('active');

        if($('.sidebar').hasClass('active')){
            $('.sidebar ul li a span').hide();
            $('.sidebar-image').addClass('col-lg-12');
            $('.accordion-button::after').hide();
        }else{
            $('.sidebar ul li a span').show();
            $('.sidebar-image').removeClass('col-lg-12');
            $('.accordion-button::after').show();
        }

        $('.accordion-button').addClass('collapsed');
        $('.accordion-collapse').removeClass('show');

        $('.dataTable').DataTable().columns.adjust().draw();
    });
    if($(window).width() > 768){
        $('.sidebarCollapse-btn').trigger('click')
    }
    $( window ).resize(function(){
        
        if($(window).width() > 768){
            $('.sidebarCollapse-btn').trigger('click')
        }
        $('.dataTable').DataTable().columns.adjust().draw();
    })

    $('.accordion-button').off().on('click', () => {
        if($('.sidebar').hasClass('active')){
            $('.sidebar').removeClass('active');
            $('.sidebarCollapse-btn').removeClass('active');
            $('.content').removeClass('active');
            $('.sidebar ul li a span').show();
            $('.sidebar-image').removeClass('col-lg-12');
        }
    });

    $('.menu-btn').off().on('click', () => {
        _link().then((val) => {
            window.location.href = val.url;
        })
    });

    $('.logout-btn').off().on('click', () => {
        Swal.fire({
            html: "Are you sure you want to Sign-Out?",
            icon: 'question',
            focusConfirm: true,
            allowOutsideClick: false,
            showClass: {
                popup: 'swal2-show',
                backdrop: 'swal2-backdrop-show',
            },
            showDenyButton: true,
            confirmButtonText: 'YES',
            denyButtonText: `NO `,
        }).then((result) => {
            if (result.isConfirmed) {
                _logout().then((val) => {
                    window.location.href = "/";
                })
            }
        })
    });

    $(".yearonly-datepicker").datepicker({
        changeYear: true,
        dateFormat: 'yy',
        showButtonPanel: true,
        onClose: function(dateText, inst) {$(this).val($("#ui-datepicker-div .ui-datepicker-year :selected").val())}
    }).on('focus', function () {
        $(".ui-datepicker-month, .ui-datepicker-calendar, .ui-datepicker-prev, .ui-datepicker-next").hide();
        $(".ui-datepicker-year").addClass('form-control w-100 text-center');
    });

    $(".datepicker").datepicker({
        changeYear: true,
        changeMonth: true,
        yearRange: "-150:+0",

        /*showOn: "both" ,
        buttonText: "<i class='fa fa-calendar'></i>" */
    });

    

    

});
